<template>
  <div class="login">
    <div class="login-box">
      <div class="login-box-item">
        <div class="title">
          <img class="logo" src="../assets/applogo.png" alt="" />
          <h3>扫码登录</h3>
          <span>请使用灵犀路演移动端扫码</span>
        </div>

        <div class="code">
          <img :src="state.qcode" alt="" />
        </div>
      </div>
      <div class="login-box-item">
        <div class="top">
          <h3>验证码登录</h3>
          <div class="input">
            <span>中国+86</span
            ><input
              type="text"
              v-model="state.phone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="input">
            <input
              type="text"
              v-model="state.code"
              placeholder="请输入验证码"
            /><span @click="getCode">{{ state.codeTips }}</span>
          </div>

          <div class="btn" @click="intoUpload">登录</div>
        </div>

        <div class="tips">
          <p>
            登录即代表已同意<span @click="jump(1)">《用户协议》</span
            >和<span @click="jump(2)">《隐私政策》</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { reactive, onMounted } from "vue";
import { useAxios } from "@/units/index";
import { ElMessage } from "element-plus";
const https = useAxios();
const router = useRouter();
const state = reactive({
  codeTips: "获取短信验证码",
  timer: null,
  time: 60,
  qcode: "",
  pollingTimer: null,
  phone: "",
  code: "",
});

onMounted(() => {
  getQr();
});

const getCode = async () => {

  if (state.timer) return;
  if (state.phone == "" || !/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(state.phone)) {
    return ElMessage.error("请正确输入手机号");
  }
  let checkHpone = await sendCode();
  if (checkHpone == "success") {
    state.codeTips = state.time + "s";
    state.timer = setInterval(() => {
      state.time--;
      state.codeTips = state.time + "s";

      if (state.time == 0) {
        state.codeTips = "获取短信验证码";
        state.time = 60;
        clearInterval(state.timer);
        state.timer = null;
      }
    }, 1000);
  }
};

const sendCode = () => {
  return new Promise((resolve, reject) => {
    https.post("/web/sms/login", { mobile: state.phone }).then(({ data }) => {
      if (data.success) {
        ElMessage.success("发送成功，请注意接收！");
        resolve("success");
      } else {
        ElMessage.error(data.message);
        resolve("false");
      }
    });
  });
};

const intoUpload = () => {
  if (state.phone == "" || !/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(state.phone)) {
    return ElMessage.error("请正确输入手机号");
  }

  if (state.code == "" || !/^\d{4}$/.test(state.code)) {
    return ElMessage.error("请正确输入验证码");
  }

  https
    .post("/web/login", {
      mobile: state.phone,
      code: state.code,
    })
    .then(({ data }) => {
      if (data.success) {
        ElMessage.success("登录成功!");

        sessionStorage.setItem("token", data.data.token);
        sessionStorage.setItem("name", data.data.name);
        sessionStorage.setItem("avatar", data.data.avatar);
        sessionStorage.setItem("mark", data.data.mark);
        router.push({
          path: "/upload",
        });
      } else {
        ElMessage.error(data.message);
      }
    });
};

const getQr = () => {
  https.get("/web/code").then(({ data }) => {
    console.log(data);
    if (data.success) {
      state.qcode = data.data.qcode;
      sessionStorage.setItem("token", data.data.token);
      startPolling();
    }
  });
};

const startPolling = () => {
  // 使用setTimeout进行轮询，成功后停止轮询
  function pollForLoginSuccess() {
    https.get("/web/check").then(({ data }) => {
      if (data.success) {
        ElMessage.success("登录成功!");

        // sessionStorage.setItem("token", data.data.token);
        sessionStorage.setItem("name", data.data.name);
        sessionStorage.setItem("avatar", data.data.avatar);
        sessionStorage.setItem("mark", data.data.mark);
        router.push({
          path: "/upload",
        });

        if (state.pollingTimer) {
          // 确保timer存在再清除
          clearTimeout(state.pollingTimer);
        }
      } else {
        // 如果未成功，继续轮询，例如每2秒一次
        state.pollingTimer = setTimeout(pollForLoginSuccess, 2000);
      }
    });
  }

  // 开始轮询
  state.pollingTimer = setTimeout(pollForLoginSuccess, 2000);
};

const jump = (index) => {
  if (index == 1) {
    router.push({
      path: "/userpcl",
    });
  } else {
    router.push({
      path: "/userpcy",
    });
  }
};
</script>

<style  scoped>
.login {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
}

.login-box {
  width: calc(420px + 500px);
  height: 600px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-box-item {
  height: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login-box-item:first-child {
  width: 420px;
  padding: 80px 90px 162px;
}

.login-box-item:first-child .title .logo {
  width: 80px;
  height: 80px;
}
.login-box-item:first-child .title h3 {
  font-size: 20px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 12px;
}

.login-box-item:first-child .title span {
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}

.login-box-item:first-child .code {
  width: 160px;
  height: 160px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}

.login-box-item:first-child .code img {
  width: 100%;
  height: 100%;
}

.login-box-item:last-child {
  width: 500px;
  padding: 80px 34px 60px;
  text-align: left;
}

.login-box-item:last-child .top {
  width: 100%;
  padding: 0 26px;
}

.login-box-item:last-child .top .input {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding-top: 16px;
  flex-direction: row !important;
  border-bottom: 1px solid #ccc;
}

.login-box-item:last-child .top .input input {
  border: none;
  font-size: 16px;
  outline: none;
}

.login-box-item:last-child .top .input:nth-child(2) {
  margin-top: calc(60px - 32px);
}

.login-box-item:last-child .top .input:nth-child(2) span {
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-right: 36px;
}

.login-box-item:last-child .top .input:nth-child(3) {
  justify-content: space-between;
}

.login-box-item:last-child .top .input:nth-child(3) span {
  width: 150px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #4087f7;
  cursor: pointer;
}

.login-box-item:last-child .top .btn {
  width: 380px;
  height: 40px;
  background: #4087f7;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 40px;
  cursor: pointer;
}

.login-box-item:last-child .top .btn:active {
  opacity: 0.8;
}

.login-box-item:last-child .tips {
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #999;
  line-height: 36px;
}

.login-box-item:last-child .tips span {
  font-size: 16px;
  color: #4087f7;
  cursor: pointer;
}
</style>