<template>

  <router-view></router-view>
</template>

<script setup>

</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}
</style>
