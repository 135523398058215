import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "@/view/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("@/view/UploadPage.vue"),
  },
  {
    path: "/userpcy",
    name: "userpcy",
    component: () => import("@/view/UserPrivacy.vue"),
  },
  {
    path: "/userpcl",
    name: "userpcl",
    component: () => import("@/view/UserProtocol.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
